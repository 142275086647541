.modal-create-appointment{
    .ant-card-body{
        padding: 1rem;
    }

    .cart-appoiment-left{
        .ant-card-body{

            .package_test_wrapper{
                display: flex;
                gap: 2rem;

                .packet_test{
                    width: 50%;
                }
                .location_test{
                    width: 50%;
                }

                .ant-radio-group{
                    width: 100%;
                    max-height: 22vh;
                    overflow: auto;
                    padding: 1px;
                }
            }
        }
    }

    .cart-appoiment-right{
        .ant-radio-group{
            display: flex;
            flex-wrap: wrap;
            gap: 0.3rem;
        }
    }
}