.authentication {
    height: 100vh;
    background-color: white;
    overflow-x: hidden;
    .auth-item {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .auth-form {
        display: flex;
        align-items: center;
    }
    .auth-box {
        width: 100%;
        padding: 2rem;
    }
}