.avatar-container {
  display: flex;
  align-items: center;

  img.avatar {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 0.3rem;
  }

  img.avatar-subcilinical {
    height: 2rem !important;
    width: 2rem !important;
    transition: all 0.3s ease-in-out;

    &:hover {
      filter: brightness(0.5);
      // transform: scale(1.5);
    }
  }
}

.icon-document-container {
  width: 140px;
}

.ant-list-item-action {
  margin-left: auto;
}

.ant-list-header {
  padding: 8px 24px !important;
}

// List recent activity
.list-recent-activity {
  border: none;

  .ant-list-items {
    position: relative;
    margin-top: 16px;

    .ant-list-item {
      border-bottom: none;
      border-left: 4px solid #000000;
      position: relative;
      color: #000000;
      font-weight: 400;

      &::before {
        content: "";
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background-color: #000000;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
      }

      .recent-activity-item{
        padding: 1rem 1.5rem 1rem 1.5rem;
        border: 1px solid #f0f0f0;
      }
    }
  }

}
