.news{
    background: #cfe6fb;
    border-radius: 10px;
    margin: 1rem 0px;
    h1{
        color: #0d044d;
        text-align: center;
        padding-top: 1rem;
        font-size: 24px;
    }
}

.news-list, 
.ant-news-list ul{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    align-items: center;
    padding: 16px 20px;
    flex-wrap: wrap;
    // margin-top: 1rem;
    margin-bottom: 2rem;
    @include maxWidth(1680){
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include maxWidth(1360){
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include maxWidth(991){
        grid-template-columns: 1fr 1fr;
    }
    @include maxWidth(580){
        grid-template-columns: 1fr;
    }
    .ant-card-bordered {
        border: none;
    }
    .ant-card{
        border-radius: 10px;
        overflow: hidden;
        .ant-card-cover {
            width: 100%;
            height: 150px !important;
            background: gray;
            img {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .ant-card-body{
            padding: 10px;
            height: 100px;
        }
    }
}
.news-link{
    padding-left: 16px;
    padding-bottom: 1rem;
    font-size: 16px;
    color: #0d044d;
    a{
        display: flex;
        gap: 5px;
        &:hover{
            color: gray;
        }
    }
}

.list-type-news{
    h1{
        text-align: left;
        font-size: 20px;
        padding-left: 16px;
    }
}

.news-detail-wrapper{
    margin: 1rem 0;
    border-radius: 16px;
    // .ant-card-body{
    //     display: none;
    // }
    .container-custom {
        width: 90%;
        max-width: 1360px;
        margin: 0px auto;
        padding: 2rem 0;
        .title{
            font-size: 32px;
            font-weight: bold;
        }
        .created{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
        }
        .list-type-news{
            border-top: 1px solid;
            padding-top: 1rem;
            h1{
                margin-left: 0px;
                padding-left: 0px;
            }
            .news-list{
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                padding: 0px;
            }
        }
    }
}

.pagination-wrapper{
    padding: 0rem 2rem 3rem 2rem;
}