.ant-list-pagination {
  position: relative;
  top: -2.5rem;
}

.ant-pagination{
  font-size: 12px !important;
  .ant-pagination-item{
    a{
      padding: 0 8px !important;
    }
  }
}