.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

.signs-and-allergy-container {
  display: flex;
  gap: 8px;
  height: 100%;
  overflow: auto;
}

.schedule {
  display: flex;
  margin: 8px 0;
  gap: 0.5rem;
  height: calc(100vh - 56px);

  &__calendar {
    flex-basis: 35%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .calendar-container {
      display: flex;
      gap: 8px;
      // max-height: 240px;
      max-height: 270px;
      margin-bottom: 0.5rem;

      .calendar-custom {
        flex-basis: 50%;
      }

      .signs-and-allergy {
        flex-basis: 50%;
        overflow: auto;

        &-item {
          font-size: 12px;

          &-title {
            font-weight: 600;
          }
        }
      }
    }

    .signs-and-allergy-container {
      display: flex;
      gap: 8px;
      height: 100%;
    }

    .calendar-patient {
      flex: 1;
      height: calc(100% - 286px);
    }
  }

  &__detail {
    flex: 65%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;

    // Card - Thông tin bênh nhân
    .patient-information {
      width: 100%;
      height: 20%;
      // height: fit-content;

      &-row {
        height: 100%;
        overflow: auto;
      }

      .patient-information-content {
        display: flex;
        justify-content: space-between;

        &-avatar {
          flex-basis: 10%;

          .ant-image-mask {
            border-radius: 50%;
            width: 4.125rem;
          }
        }

        &-info {
          flex-basis: 90%;

          .ant-row {
            .btn-intro-dr,
            .btn-recent-activity,
            .btn-document-picture {
              display: flex;
              align-items: center;
              span:last-child {
                flex: 1;
              }
            }
          }
          .info-group {
            display: flex;
            margin-left: 1rem;

            &-title {
              &-address {
                flex-basis: 22%;
              }
            }

            &-content {
              &-address {
                flex-basis: 78%;
              }
            }
          }
        }
      }

      .ant-card {
        height: 100%;

        &-head {
          min-height: 27px;
          font-weight: unset;
          font-size: 14px;
          padding: 0px 16px;

          &-title {
            padding: 2px;
          }
        }

        &-body {
          padding: 8px;
        }
      }
    }

    .schedule__detail_group {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: calc(80% - 8px);

      .ant-list-item {
        padding: 0px 16px !important;
      }

      .list-sub-clinic {
        .ant-spin-nested-loading {
          height: calc(100% - 27px);
        }
      }

      .ant-row {
        flex: 1;
        height: calc(50% - 4px);
        display: flex;
        align-items: center;

        .ant-col {
          height: 100%;
        }
      }
    }
  }

  // Css cho tất các các List trong Schedule
  .ant-list-header {
    max-height: 27px;
    height: 27px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-list {
    height: 100%;

    .ant-spin-nested-loading {
      height: calc(100% - 51px);

      .patient-list-item{
        padding: 4px 10px;
        .ant-list-item-meta{
          .ant-list-item-meta-title{
            display: flex;
            gap: 10px;
            align-items: center;

            .ant-list-item-meta-title-right{
              flex: 1;
            }
          }
        }
      }

      .ant-spin-container {
        height: 100%;

        .ant-list-items {
          cursor: pointer;
          height: 100%;
          overflow: auto;
        }
      }
    }
  }

  .ant-list-footer {
    padding: 0px 16px !important;
  }

  // Css List cho 2 khối
  .calendar-patient,
  &__detail {
    .ant-list-item {
      &:hover {
        background: #f0f2f5;
      }
      &.active {
        background: #f0f2f5;
      }

      &-meta-title {
        margin-bottom: 0;

        .patient-booking-name {
          display: flex;
          justify-content: space-between;

        }
      }

      &-meta-description {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);

        .patient-booking-detail {
          display: flex;
          justify-content: space-between;

          .status-booking{
            display: flex;
            margin-bottom: 4px;
            .status-booking-label{
              min-width: 150px;
            }
            .status-booking-value{
              span{
                margin-bottom: 4px;
              }
            }
          }
        }
      }
    }
  }
}

.calendar-patient-list {
  .ant-list-item-meta-description {
    .patient-booking-detail {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .ant-col-24 {
        .ant-row {
          margin-top: 10px;
          padding-bottom: 6px;
          .ant-col.ant-col-2 {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.list-detail-subclinical{
  .ant-list-items{
    .list-detail-subclinical-doctor{
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      padding-right: 10px;
    }
    .list-detail-subclinical-item{
      display: unset;
      .list-detail-subclinical-image{
        display: flex;
        gap: 8px;
        margin: 10px 0px;
      }
      .list-detail-subclinical-file{
        display: flex;
        flex-direction: column;
        // gap: 10px;
        .tag-subclinical{
          height: 65px;
          padding: 5px 15px;
          border-bottom: none;
          border-left: 4px solid #000000;
          position: relative;
          font-weight: 400;
          overflow: visible;
          display: flex;
          align-items: center;
          &::before{
            content: "";
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background-color: #000000;
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
          }
          .subclinical-box{
            // display: flex;
            // align-items: center;
            // gap: 10px;
            // justify-content: space-between;
            padding: 5px 10px;
            width: 100%;
            border: 1px solid rgb(219, 219, 219);
            height: 55px;
            overflow: hidden;
            &:hover{
              border: 1px solid gray;
            }
            .file-name{
              width: calc(100% - 200px);
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .anticon.anticon-file-pdf{
              width: 35px;
              height: 35px;
              svg{
                width: 35px;
                height: 35px;
              }
            }
          }
        }
      }
    }
  }
}