.btn-flex-end {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.btn-flex-start {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}
.ant-modal-header {
  // padding-bottom: unset;
}

.button-active {
  background-color: #0083cb !important;
  color: white !important;
}
