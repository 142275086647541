.form-center {
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  justify-content: center;
  align-items: center;
}
.form-center-30 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  align-items: center;
}
.form-center-20 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: center;
  align-items: center;
}
