.bg-white {
  background: #fff;
}
.order-info {
  &-group {
    margin-left: 0;
    margin-top: 0.7rem;
  }
}
.service__result-image {
  .ant-list-items {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    & > .ant-list-item {
      border-bottom: 0;
      padding-bottom: 0;
      a {
        img {
          max-width: 230px;
          max-height: 95px;
          width: auto;
          height: auto;
        }
      }
    }
  }
  margin-bottom: 1.5rem;
}
.list-images__result-item {
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
}

.IBMPlexSans-Bold{
  font-family: "IBMPlexSans-Bold";
}

.mb-12{
  margin-bottom: 12px;
}

.fs-16{
  font-size: 16px;
}
.fs-18{
  font-size: 18px;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-1{
  gap: 1rem;
}
.gap-2{
  gap: 2rem;
}

.text-bold{
  font-weight: 700;
}

.text-semibold{
  font-weight: 600;
}

.text-normal{
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.mr-1{
  margin-right: 1rem;
}

.mb-1{
  margin-bottom: 1rem;
}

.mt-1{
  margin-top: 1rem;
}

.ml-1{
  margin-left: 1rem;
}

.ml-4px{
  margin-left: 4px;
}