.signs-allergy {
    // margin-top: 2.8rem;

    .description-top {
        .ant-descriptions-item-label {
            width: 33.33%;
        }
    }

    .description-bottom {
        .ant-descriptions-item-label {
            width: 25%;
        }
    }

    .description-top,
    .description-bottom {
        .ant-descriptions-item-content {
            span {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.form_signs {
    .ant-form-item{
        .ant-row {
            .ant-col {
                width: 110px;
                text-align: left;
            }
        }
    }
}
