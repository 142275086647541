.admin-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 1rem;

  .flex-fill {
    flex: 1;
    .wp-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      &-left {
        display: flex;

        button {
          margin-right: 0.5rem;
        }
      }
    }
    .wp-action-detail {
      position: absolute;
      right: 0;
      top: -33px;
    }
  }
}

.ant-table-wrapper {
  .ant-table-thead {
    .ant-table-cell {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      height: 22.8px;
      max-height: 34px !important;

      .ant-table-cell {
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;

        &-name {
          display: flex;
          align-items: center;

          img {
            height: 1.25rem;
            width: 1.25rem;
            border-radius: 50%;
            object-fit: "cover";
            margin-right: 0.3rem;
          }
        }

        .address-patient {
          display: block; /* Fallback trong trường hợp trình duyệt không hỗ trợ display dạng box */
          display: -webkit-box;
          line-height: 16px; /* Fallback  */
          max-height: 100%; /* Fallback số dòng bạn muốn giới hạn */
          -webkit-line-clamp: 2; /* Số dòng bạn muốn giới hạn */
          visibility: visible; /* Tránh bug không giới hạn số dòng được do trình duyệt */
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 300px; /* Mình giới hạn độ dài paragraph để nó xuống dòng phục vụ demo thôi */
        }

        .anticon {
          cursor: pointer;
          padding: 9px 9px;
        }
      }
    }
  }
}

.ant-upload-list-item {
  margin-bottom: 8px;
}
