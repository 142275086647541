.OrderTest {
  .display-flex {
    display: flex;
  }
  .order-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    .ant-form-item {
      .ant-form-item-label {
        width: 32%;
        text-align: left;
        margin-right: 10px;
        font-weight: 600;
      }
      .ant-form-item-control {
        width: 100%;
      }
    }
    .label_width_max {
      .ant-form-item-label {
        width: 100% !important;
      }
    }
  }
  .package_test_wrapper {
    padding-top: 20px;
    .ant-radio-group {
      width: 100%;
      label {
        border-color: none;
        height: 40px;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        border-left: 1px solid #d9d9d9;
        &:nth-child(1) {
          &.ant-radio-button-wrapper-checked {
            border-color: rgb(179, 11, 11);
            box-shadow: unset;
          }
        }
        &:nth-child(2) {
          &.ant-radio-button-wrapper-checked {
            border-color: rgb(254, 199, 5);
            box-shadow: unset;
          }
        }
        &:nth-child(3) {
          &.ant-radio-button-wrapper-checked {
            border-color: rgb(69, 175, 221);
            box-shadow: unset;
          }
        }
      }
    }
    .location_test {
      .ant-radio-group.ant-radio-group-solid {
        label {
          &:nth-child(1) {
            &.ant-radio-button-wrapper-checked {
              border-color: rgb(3, 144, 252);
              box-shadow: unset;
            }
          }
          &:nth-child(2) {
            &.ant-radio-button-wrapper-checked {
              border-color: rgb(254, 199, 5);
              box-shadow: unset;
            }
          }
        }
      }
    }
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):active:before {
    background-color: transparent;
    border: none !important;
    color: black;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover:before {
    background-color: transparent;
    border: none !important;
  }
  .ant-radio-button-wrapper:not(:first-child):before {
    display: none;
  }
  .ant-col.ant-col-9 {
    .ant-card.ant-card-bordered {
      margin-top: 0 !important;
    }
  }
  .card-order-left {
    height: unset;
  }
  .ant-card.ant-card-bordered.card-order-left {
    .ant-card-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .button_save {
    .ant-card-body {
      padding: 20px;
    }
    .ant-form-item {
      margin-bottom: 0px !important;
    }
    .ant-form-item-control-input-content {
      margin-bottom: 0;
    }
  }
  .info_patient {
    .order-info {
      .ant-row {
        display: flex;
        justify-content: space-between;
        .ant-col.ant-col-12 {
          flex: 0 0 48%;
          .info-group-title {
            flex-basis: 34%;
            margin-right: 1%;
          }
          .info-group-content {
            flex-basis: 65%;
          }
        }
      }
    }
  }
}
.ant-modal-close-x {
  border: 1px solid rgb(69, 175, 221);
  background: rgb(69, 175, 221);
  svg {
    path {
      fill: #fff;
    }
  }
}
.menu_control_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .button_add {
    h4 {
      padding: 5px 20px;
      border: 3px;
      background: rgb(69, 175, 221);
      color: #fff;
      margin: 0;
    }
  }
}

.call_to_action {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    .ant-form-item {
      padding-top: 8px;
      margin-right: 15px !important;
      .ant-form-item-control-input-content {
        margin-bottom: 0;
      }
      button {
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .wp_btn{
      display: flex;
    }
  }
}

.item_sample_ordertest {
  .ant-form-item-row {
    flex-wrap: wrap;
    .ant-form-item-label {
      width: 100%;
      text-align: left;
    }
    .ant-form-item-control {
      width: 100%;
      flex: unset;
    }
  }
}
.orderTest_form {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0 !important;
    margin-top: 10px;
    button {
      padding: 10px 35px;
      height: unset;
      max-height: 45px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.select_sample_order_test {
  margin-bottom: 20px;
}
