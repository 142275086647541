ul {
  margin-bottom: 0;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.schedule-detail {
  .ant-card {
    height: 100%;
    &-head {
      min-height: 30px;
      &-title {
        padding: 0px;
      }
    }
    &-body {
      padding: 8px;
    }
  }
}
.admin-table.my-patient {
  .ant-table-content {
    .ant-table-thead {
      tr {
        th {
          padding: 10px 0;
          text-align: center;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          padding: 10px 8px;
          &:nth-child(2),
          &:nth-child(6),
          // &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            text-align: center;
          }
          &:nth-child(3) {
            width: 20%;
          }
          .item-avatar-profile {
            .avatar-container {
              img {
                height: 2rem;
                width: 2rem;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.admin-table.my-patient-result {
  .ant-table-content {
    .ant-table-thead {
      tr {
        th {
          padding: 10px 0;
          text-align: center;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          padding: 10px 0;
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            text-align: center;
          }
          &:nth-child(2) {
            width: 20%;
          }
          .item-avatar-profile {
            .avatar-container {
              img {
                height: 2rem;
                width: 2rem;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

#form_add_prescription {
  .ant-form {
    background-color: red;
  }
  .ant-col-3 {
    flex: 0;
    .ant-card-body {
      padding: 0;
      margin-right: 1rem;
    }
  }
}