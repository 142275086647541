.icon-delete {
  .icon-container {
    cursor: pointer;
    padding: 0 !important;
  }
}
.my-patient{
  .icon-container {
    padding: 6px 6px !important;
    svg{
      width: 20px;
      height: 20px;
    }
  }
}

.icon-container, .anticon.icon-containe {
  cursor: pointer;
  padding: 9px 9px !important;
  .icon-text {
    &:hover {
      color: rgb(24, 144, 255);
    }
  }
}

.hover-avatar {
  cursor: pointer;
}

.format-button {
  border: unset;
}

.format-a {
  color: rgba(0, 0, 0, 0.5);
}

.format-row-ant {
  height: 1.5rem;
}

.icon-color {
  color: rgb(24, 144, 255);
}

.align-center {
  display: flex;
  align-items: center;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row .ant-table-cell .anticon {
  cursor: pointer;
  padding: 0 !important;
}
