.sample-prescription {
    &-add {
        .form-add-prescription {
            .card-date-note .ant-form-item .ant-row .ant-col {
                width: 150px;
                text-align: left;
            }

            .ant-row.ant-form-item-row {
                .ant-select-selector {
                    max-height: 176px;
                    overflow: auto;
                }
            }

            .ant-card-body {
                .ant-form-item {
                    .ant-form-item-control-input-content {
                        .ant-btn.ant-btn-primary {
                            background: #0083cb;
                            padding: 18px 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}