.list-image {
  img {
    &:hover {
      opacity: 0.6;
    }
  }
}

.tag-subclinical-profile {
  .tag-subclinical {
    width: 260px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      color: #5fd1c1 !important;

      transition: all 0.3s ease-in-out;
      &:hover {
        filter: brightness(0.5);
      }
    }
  }
}

.tag-subclinical {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    transition: all 0.3s ease-in-out;
    &:hover {
      filter: brightness(0.5);
    }
  }
}
