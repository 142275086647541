.prescription {
  // Form add prescription
  &-add {
    .ant-row.ant-form-item-row {
      .ant-select-selector {
        max-height: 176px;
        overflow: auto;
      }
    }
  
    .ant-card-body {
      .ant-form-item {
        .ant-form-item-control-input-content {
          .ant-btn.ant-btn-primary {
            background: #0083cb;
            padding: 18px 40px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    &-select {
      .ant-card-body {
        padding-top: 8px;
        //     padding-bottom: 0;
        //     padding-left: 0;
      }

      .ant-form-item {
        margin-bottom: 8px;
        // margin: 0 0 8px !important;
      }

      .amount-medicine {
        &-content {
          display: flex;

          .ant-form-item {
            margin-right: 0.3rem;

            .ant-input-number {
              width: 100%;
            }
          }
        }
      }

      .total {
        display: flex;

        // padding: 10px;
        .total-title {
          flex-basis: 45%;
        }

        .total-price {
          flex-basis: 55%;
        }
      }

      .sub-total {
        display: flex;
        justify-content: space-between;
      }
    }

    .form-add-prescription {
      .card-date-note {
        .ant-form-item {
          .ant-row {
            .ant-col {
              width: 120px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.ant-form-item {
  margin: 0 0 8px !important;
}

.profile-doctor-container {
  .ant-modal-body {
    padding-top: 0.5rem !important;
  }
}
.table_add_prescription {
  margin: 15px 0;
  tr {
    th,
    td {
      text-align: center;
      width: 9%;
      input {
        text-align: center;
        width: 100%;
        min-height: 32px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        outline: none;
      }
      .ant-select {
        width: 100%;
        .ant-select-selector {
          border: 1px solid rgba($color: #000000, $alpha: 0.2);
        }
      }
      button {
        height: 32px;
        cursor: pointer;
      }
      &:first-child {
        width: 50px;
        input {
          width: 50px;
        }
      }
      &:nth-child(2) {
        width: 22%;
        text-align: left;
      }
      &:nth-child(4) {
        width: 16%;
      }
      &.input-event-none {
        input {
          pointer-events: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }

      &.addRow {
        // button {
        //   background-color: #25d366;
        //   color: #fff;
        //   border: 1px solid #25d366;
        //   outline: none;
        //   box-shadow: none;
        //   border-radius: 3px;
        // }
      }
      &:last-child {
        // button {
        //   background-color: #bd081c;
        //   color: #fff;
        //   border: 1px solid #bd081c;
        //   outline: none;
        //   box-shadow: none;
        //   border-radius: 3px;
        // }
      }
    }
    .addRow {
      width: 50px;
      + {
        td {
          width: 50px;
        }
      }
    }
  }
}
.addPrescription {
  border: 1px solid #0083cb;
  color: #fff;
  background: #0083cb;
  border-radius: 2px;
  padding: 7px 20px;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
}
.ant-col.ant-col-24 {
  .ant-card.ant-card-bordered.ant-card-small {
    border: none;
    .ant-card-body {
      padding: 0;
      .ant-form-item {
        margin: 0 !important;
      }
    }
  }
}
.totalPrice {
  display: flex;
  align-items: center;
  font-size: 18px;
  h4 {
    margin-bottom: 0;
  }
  span {
    margin-left: 6px;
    font-weight: bold;
  }
}
.addPrescription__wapper {
  justify-content: end;
}
