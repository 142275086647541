html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 320px;
}

ul {
  list-style: none;
  padding-left: 0;
}

.ql-editor {
  min-height: 200px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
}

.notification-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.info-group {
  display: flex;
  margin-left: 2rem;

  &-title {
    flex-basis: 40%;
    font-weight: bold;
  }

  &-content {
    flex-basis: 60%;
  }
}

.info-group-profile {
  display: flex;

  &-title {
    flex-basis: 40%;
    font-weight: bold;
  }

  &-content {
    flex-basis: 60%;
  }
}

.admin {
  .ant-layout-header {
    max-height: 40px;
    line-height: 2;
    padding: 0 16px;
    background-color: #0d044d;
    .trigger {
      padding: 10px 24px;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: #1890ff;
      }
    }
    .ant-menu {
      background-color: #0d044d;
      height: 100%;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #fff;
        }
        @media (max-width: 1300px) {
          padding: 0 10px;
        }
      }
    }
    .top-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      height: 100%;

      &-item {
        padding: 0.25rem;
        cursor: pointer;

        .ant-btn {
          border: none;

          .dot {
            position: absolute;
            top: 6px;
            right: 10px;
          }
        }

        .avatar-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .avatar {
            vertical-align: middle;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;

            &:hover {
              filter: brightness(0.5);
              // transform: scale(1.5);
            }
          }

          span {
            padding-left: 0.5rem;
            cursor: pointer;
          }
        }
      }
    }
  }

  .ant-layout-content {
    height: calc(100vh - 40px);
    overflow: auto;
  }

  height: 100vh;

  .site-layout .site-layout-background {
    display: flex;
    justify-content: space-between;
    background: white;
  }

  .logo {
    width: 120px;
    height: 30px;
    // height: 32px;
    // margin: 16px;
    // background: rgba(255, 255, 255, 0.3);
  }

  &-table {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 1rem;

    .flex-fill {
      flex: 1;
    }
  }

  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
}

.btn-success {
  color: #ffffff;
  border-color: #198754;
  background: #198754;
}

.btn-success:hover {
  background-color: #198754;
  opacity: 0.8;
  border: 1px solid #198754;
}

.btn-info {
  color: #ffffff;
  border-color: #0dcaf0;
  background: #0dcaf0;
}

.btn-info:hover {
  background-color: #0dcaf0;
  opacity: 0.8;
  border: 1px solid #0dcaf0;
}

.icon-header {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.modal-scroll {
  // height: 100vh;
  max-height: 99vh;
  overflow: auto;
}

// .scroll-checkbox {
//   height: 210px;
//   overflow: auto;
// }

.scroll-checkbox {
  max-height: 350px;
  overflow-y: scroll;
}

::-webkit-scrollbar-corner {
  background: #f6f6f6;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 4px;
  border: 2px solid #f6f6f6;
}

.list-images {
  img {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      filter: brightness(0.5);
    }
  }
}

.item-avatar-profile {
  // overflow: hidden;
  img {
    transition: all 0.3s ease-in-out;
  }
  img:hover {
    filter: brightness(0.5);
  }

  .item-text-profile:hover {
    cursor: pointer;
    color: rgb(24, 144, 255);
  }
}

.item-text-profile:hover {
  cursor: pointer;
  color: rgb(24, 144, 255);
}

.avatar-subcilinical {
  height: 2rem !important;
  width: 2rem !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  height: 16px !important;
  line-height: 14px !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 18px !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector:after {
  line-height: 14px !important;
}

.text-ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
}