a {
  color: #000000;
}

.ant-col {
  // padding-bottom: 1.2rem;
}

.hover-cuser {
  cursor: pointer;
  &:hover {
    color: rgb(24, 144, 255);
  }
}
.order-content_wapper {
  .ant-form-item-row {
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
  }
}
