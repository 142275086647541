.statistical {
  background: #cfe6fb;
  border-radius: 10px;

  h1 {
    color: #0d044d;
    text-align: center;
    padding-top: 1rem;
    font-size: 24px;
  }

  .rangerpicker {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .list-statistical {
    .ant-list-split .ant-list-item {
      .ant-list-item-meta {
        display: flex !important;
        align-items: center !important;

        .ant-list-item-meta-avatar {
          margin-left: 10px;
        }

        .ant-list-item-meta-title {
          color: #0d044d;
        }

        .ant-list-item-meta-description {
          color: #45afdd;
        }
      }
    }
  }
}

.ant-picker-content th:nth-child(5n + 6),
.ant-picker-content th:nth-child(5n + 7) {
  color: red !important;
  /* Bạn có thể thêm các thuộc tính CSS khác tại đây */
}

.drawer-notification{
  .ant-drawer-body{
    padding: 4px 24px;
  }
}