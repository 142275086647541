.personal-information {
    .ant-image-mask {
        border-radius: 50%;
    }

    .personal-information,.billing-information,.contact-information{
        .ant-form-item {
            .ant-col {
                text-align: left;
                width: 180px;
            }
        }
    }

    .wrapper_published .ant-btn{
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
    }

    .wrapper_profile{
        .tag_profile{
            width: 100%;
            overflow: hidden;
        }
    }
}