.ant-picker-cell-inner {
  position: relative;
}

.dot-marker {
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: #878678;
  border-radius: 50%;
}


.ant-picker-calendar {
  .ant-row {
    padding: 4px 16px !important;
  }

  .ant-picker-panel {
    font-size: 12px;

    .ant-picker-date-panel {
      width: 100%;

      .ant-picker-body {
        padding: 4px 0 !important;

        table.ant-picker-content {
          height: unset;

          thead {
            tr {
              th {
                width: 2rem;
              }
            }
          }
        }
      }
    }
  }
}