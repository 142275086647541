@mixin font-face($font-family, $url) {
    @font-face {
        font-family: "#{$font-family}";
        src: url("../#{$url}.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
}


@mixin split_str($line_number) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line_number;
}

@mixin minAndMaxWidth($min,
    $max) {
    @media only screen and (min-width: "#{$min}px")and (max-width: "#{$max}px") {
        @content;
    }
}

@mixin maxWidth($breakpoint) {
    @media only screen and (max-width: "#{$breakpoint}px") {
        @content;
    }
}

@mixin minWidth($breakpoint) {
    @media only screen and (min-width: "#{$breakpoint}px") {
        @content;
    }
}

@mixin mb() {
    @include maxWidth(991) {
        @content;
    }
}

@mixin hd() {
    @include maxWidth(1400) {
        @content;
    }
}

@mixin box($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin absoluteCenter() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin flexSpaceBetween {
    display: flex;
    justify-content: space-between;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-column-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin absoluteFull() {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}
