.size-xs {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  display: inline;
  font-weight: 400;
}


.font-bold{
    font-weight: bold;
}
