.dictionary {
  .ant-list-pagination {
    padding-top: 1rem;
  }
  &__card {
    .ant-card-head {
      border-color: #0083cb;
    }
  }
  .dictionary-details {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
