
.drawer-chat{
    .header-message{
        display: flex;
        gap: 10px;
    }
    .ant-drawer-body{
        background-color: #cfe6fb;
        .overflow-ellipsis{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
        }
        .chat-item{
            transition: all 0.2s linear;
            border-radius: 10px;
            &:hover{
                background: rgb(0,0,0,0.1) !important;
            }
            .chat-box{
                
                width: calc(100% - 35px);
                display: flex;
                padding: 0 15px;
                justify-content: space-between;
                *{
                    font-size: 14px;
                }
            }
        }
        .full-height{
            height: 100%;
        }
        .message-box-wrapper{
            display: flex;
            flex-direction: column;
            height: 100%;
            .message-list-wrapper{
                height: calc(100% - 80px);
                width: 100%;
                display: flex;
                align-items: flex-end;
                *{
                    height: 100%;
                }
                .ant-list{
                    width: 100%;
                }
                .ant-spin-container{
                    overflow-y: auto;
                    margin-right: -15px;
                    padding-right: 8px;
                }
                ul.ant-list-items{
                    display: flex;
                    flex-direction: column-reverse;
                    width: 100%;
                    height: unset;
                    padding: 1rem 0;
                    .message-item{
                        width: 100%;
                        li.ant-list-item{
                            padding: 8px 0;;
                            .chat-box{
                                padding: 5px 10px;
                                background-color: white;
                                border-radius: 14px;
                                .chat-content{
                                    max-width: 380px;
                                    word-break: break-word;
                                    font-weight: 600;
                                }
                                .ant-image{
                                    border-radius: 10px;
                                    overflow: hidden;
                                }
                            }
                            &.author-message{
                                display: flex;
                                justify-content: flex-end;
                                .chat-box{
                                    background-color: #2851A3;
                                    color: white;
                                }
                                .chat-send-time{
                                    text-align: end;
                                }
                            }
                        }
                       
                    }
                   
                }
            }
            .message-input{
                margin: auto 0;
                height: 80px;
                width: 100%;
                position: relative;
                padding-top: 5px;
                background: #fff;
                border-radius: 10px;
                padding: 5px;
                position: relative;
                .button-send{
                    position: absolute;
                    right: 10px;
                    top: 5px;
                }
                .button-upload{
                    position: absolute;
                    right: 10px;
                    top: 44px;
                }
                form{
                    width: 100%;
                    height: 100%;
                }
                textarea{
                    height: 100% !important;
                    width: calc(100% - 50px);
                    padding: 5px 10px 5px 10px;
                    border: none;
                    &:focus{
                        outline: none;
                        border: none;
                    }
                }
            }
        }
    }
}
.time-group{
    display: flex;
    align-items: center;
    gap: 8px;
    .dot{
        display: block;
        width: 10px;
        height: 10px;
        background: blue;
        border-radius: 50%;
    }
}
.message-btn{
    position: relative;
    position: fixed;
    bottom : 30px;
    right : 20px;
    width: 50px;
    height: 50px;
    svg{
        font-size: 25px;
    }
    .dot{
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 15px;
        height: 15px;
        background: red;
    }
}
.load-more-message{
    display: flex;
    justify-content: center;
}